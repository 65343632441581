import React from "react";
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl";
import "./ProjectCard.css";
import { compose } from "redux";
import { Img } from "react-image-loading";
import styled from "styled-components";
import slugify from "slugify";

const ProjectCard = (props) => {
  const { title, summary, finished, headerPicture, even, isMayaHome } = props;
  return (
    <Container even={even}>
      {finished ? <Finished> VOLTOOID </Finished> : null}
      <StyledImg src={headerPicture} alt="Avatar" />

      <InnerContainer>
        <CardTitle>
          <b>{title}</b>
        </CardTitle>

        <CardSummary>{summary}</CardSummary>
        <LinkContainer>
          <StyledDonateButton
            to={
              isMayaHome
                ? `/donation/?type=home#step2`
                : `/donation?type=home#step2`
            }
          >
            <FormattedMessage id="donate" defaultMessage="Doneren" />
          </StyledDonateButton>
          <StyledReadMore
            to={
              isMayaHome
                ? `/maya-home`
                : `/projects/${slugify(title, { lower: true })}`
            }
          >
            <FormattedMessage id="readMore" defaultMessage="Lees meer" />{" "}
            <i className="arrow-leesmeer-right"></i>
          </StyledReadMore>
        </LinkContainer>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 355px;
  min-height: 465px;
  display: flex;
  background-color: #fef4ea;
  margin-top: 40px;
  border-radius: 3px;
  position: relative;
  flex-direction: column;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
  }
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
  /* box-shadow: ${({ even }) =>
    even ? `#2a7ec8 20px -20px` : `#F72134 20px -20px`}; */
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 200.9px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: transparent;
  margin-bottom: 0px;
  object-fit: cover;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
`;

const CardSummary = styled.p`
  margin-left: 0px;
  margin-top: 5px;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  padding-right: 15px;
  padding-bottom: 15px;
  text-align: left;
  color: #333333;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 310px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 22px;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
    position: relative;
    bottom: 0px;
  }
`;

const Finished = styled.div`
  width: 102px;
  height: 40px;
  border-radius: 3px;
  font-family: "Nunito Sans";
  font-size: 14px;
  border: 2px solid #333;
  font-weight: 600;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  right: -7px;
  color: #f58320;
  position: absolute;
  overflow: visible;
  background-color: white;
  top: 15px;
`;

const CardTitle = styled.h4`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;

  display: inline-block;
`;

const StyledDonateButton = styled(Link)`
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: white;
  position: relative;
  text-decoration: none;
`;

const StyledReadMore = styled(Link)`
  margin-top: 0;
  cursor: pointer;
  height: 22px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  text-decoration: none;
`;

export default compose(injectIntl)(ProjectCard);
